import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types';
import { NavMenu } from '../navmenu/NavMenu';
import Image from 'react-bootstrap/Image';
import { ReactSession } from 'react-client-session';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/free-regular-svg-icons'

import { Dashboard } from '../admin/Dashboard'
import { Users } from '../admin/Users'
import { MasterWallet } from '../admin/MasterWallet'
import { UserWallet } from '../admin/UserWallet'
import { SampleRequests } from '../admin/SampleRequests'
import { Clients } from '../admin/Clients'
import { CreateCategory } from '../admin/CreateCategory'
import { AssignCategoryPrice } from '../admin/AssignCategoryPrice'
import { SellerWithdrawalList } from '../admin/SellerWithdrawalList'
import { SellerWithdrawalReport } from '../admin/SellerWithdrawalReport'
import { UploadNumbers } from '../admin/UploadNumbers'
import { SpecialNumbers } from '../admin/SpecialNumbers'
import { ArchiveHistory } from '../admin/ArchiveHistory'
import { DepositRequests } from '../admin/DepositRequests'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/style.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
import '../root/dashboard/css/style.css';
import '../root/dashboard/css/sidemenu.css';

import logo from '../root/images/logo/logo.png';

class AdminLayout extends React.Component {
    static displayName = AdminLayout.name;

    constructor(props) {
        super(props);

        /*this.state = { theme: "light" };*/

        //this.Theme.useDarkMode();
        //this.ThemeToggle.useDarkMode();
    }
    //toggleTheme = () => {
    //    if (this.state.theme === 'dark') {
    //        this.setState({theme: 'light'});
    //    } else {
    //        this.setState({ theme: 'dark' });
    //    }
    //}

    componentDidMount() {
        $('body').attr('class', '');
        $(document).on('mouseenter mouseleave', '.sidebar .nav-item', function (ev) {
            var body = $('body');
            var sidebarIconOnly = body.hasClass("sidebar-icon-only");
            var sidebarFixed = body.hasClass("sidebar-fixed");
            if (!('ontouchstart' in document.documentElement)) {
                if (sidebarIconOnly) {
                    if (sidebarFixed) {
                        if (ev.type === 'mouseenter') {
                            body.removeClass('sidebar-icon-only');
                        }
                    } else {
                        var $menuItem = $(this);
                        if (ev.type === 'mouseenter') {
                            $menuItem.addClass('hover-open')
                        } else {
                            $menuItem.removeClass('hover-open')
                        }
                    }
                }
            }
        });
    }

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }
    signOutClick() {
        localStorage.clear();
        window.location.href = "/"
    }

    state = {
        modal: false
    }

    selectModal = (info) => {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        const { match } = this.props
        return (
            <div className="container-scroller">
                <NavMenu />
                <nav id="navigationBar" className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                    <div className="navbar-menu-wrapper d-flex d-lg-inline-flex justify-content-between align-items-top shadow-sm menuWrpper">
                        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center border">
                            <div className="me-3">
                                <h1 className="welcome-text"><Image src={logo} className="img-fluid" /></h1>
                            </div>
                            <div>
                                <a className="navbar-brand brand-logo" href="index.html">
                                    {/* <img src="images/logo.svg" alt="logo" />*/}
                                </a>
                                <a className="navbar-brand brand-logo-mini" href="index.html">
                                    {/* <img src="images/CorazonLogo.svg" alt="logo" />*/}
                                </a>
                            </div>
                        </div>
                        <ul className="navbar-nav mt-4">
                            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center me-md-3" type="button" onClick={this.toggleOffcanvas}>
                                <Image src="/images/Action/toggleMenu.svg" className="img-fluid togImage togThemeLight" />
                                {/*<Image src="/images/Action/toggleMenuLight.svg" className="img-fluid togImage togTheme" />*/}
                            </button>
                            <li className="nav-item font-weight-semibold d-block">
                                <button className="navbar-toggler navbar-toggler calendarPanel align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                                    <i className="mdi mdi-18px mdi-format-align-left togImage togThemeLight"></i>
                                    {/*  <Image src="/images/Action/toggleMenuLight.svg" className="img-fluid togImage togTheme" />*/}
                                </button>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto me-md-0 ms-md-auto mt-4">

                            <li className="nav-item calendarPanel">
                                <i className="mdi mdi-fullscreen-exit"></i>
                            </li>
                            <li className="col-xl-8 d-none">
                                <input type="text" className="form-control menuSearch ps-5" placeholder="Search..." />
                                <Image src="/images/search.svg" className="img-fluid searchIcon" />
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link count-indicator calendarPanel" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                                    <i className="mdi mdi-bell"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
                                    <a className="dropdown-item py-3 border-bottom">
                                        <p className="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                                        <span className="badge badge-pill badge-primary float-right">View all</span>
                                    </a>
                                    <a className="dropdown-item preview-item py-3">
                                        <div className="preview-thumbnail">
                                            <i className="mdi mdi-alert m-auto text-primary"></i>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                                            <p className="fw-light small-text mb-0"> Just now </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item py-3">
                                        <div className="preview-thumbnail">
                                            <i className="mdi mdi-settings m-auto text-primary"></i>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject fw-normal text-dark mb-1">Settings</h6>
                                            <p className="fw-light small-text mb-0"> Private message </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item py-3">
                                        <div className="preview-thumbnail">
                                            <i className="mdi mdi-airballoon m-auto text-primary"></i>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                                            <p className="fw-light small-text mb-0"> 2 days ago </p>
                                        </div>
                                    </a>
                                </div>
                            </li>

                            <li className="nav-item dropdown user-dropdown">
                                <a className="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="mx-1">
                                        <img src="../images/user-icon.jpg" className="img-fluid" />
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown pb-0" aria-labelledby="UserDropdown">
                                    <div className="drop-heading">
                                    <p className="p-2 pb-0 m-0 f-14">Admin</p>
                                        <p className="px-2">Administrator</p>
                                        </div>
                                    <a className="dropdown-item border-top" onClick={this.selectModal}><i className="dropdown-item-icon mdi mdi-18px mdi-account-outline text-primary me-2"></i> My Profile</a>
                                    <a className="dropdown-item" onClick={this.signOutClick}><i className="dropdown-item-icon mdi mdi-18px mdi-power text-primary me-2"></i>Sign Out</a>
                                </div>
                            </li>
                        </ul>

                    </div>
                </nav>

                <div className="container-fluid page-body-wrapper pt-0">

                    <div id="right-sidebar" className="settings-panel">
                        <i className="settings-close ti-close"></i>
                        <ul className="nav nav-tabs border-top" id="setting-panel" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="todo-tab" data-bs-toggle="tab" href="#todo-section" role="tab" aria-controls="todo-section" aria-expanded="true">TO DO LIST</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="chats-tab" data-bs-toggle="tab" href="#chats-section" role="tab" aria-controls="chats-section">CHATS</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="setting-content">
                            <div className="tab-pane fade show active scroll-wrapper ps ps--active-y" id="todo-section" role="tabpanel" aria-labelledby="todo-section">
                                <div className="add-items d-flex px-3 mb-0">
                                    <form className="form w-100">
                                        <div className="form-group d-flex">
                                            <input type="text" className="form-control todo-list-input" placeholder="Add To-do" />
                                            <button type="submit" className="add btn btn-primary todo-list-add-btn" id="add-task">Add</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="list-wrapper px-3">
                                    <ul className="d-flex flex-column-reverse todo-list">
                                        <li>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="checkbox" type="checkbox" />
                                                    Team review meeting at 3.00 PM
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <i className="remove ti-close"></i>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="checkbox" type="checkbox" />
                                                    Prepare for presentation
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <i className="remove ti-close"></i>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="checkbox" type="checkbox" />
                                                    Resolve all the low priority tickets due today
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <i className="remove ti-close"></i>
                                        </li>
                                        <li className="completed">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="checkbox" type="checkbox" />
                                                    Schedule meeting for next week
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <i className="remove ti-close"></i>
                                        </li>
                                        <li className="completed">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="checkbox" type="checkbox" />
                                                    Project review
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <i className="remove ti-close"></i>
                                        </li>
                                    </ul>
                                </div>
                                <h4 className="px-3 text-muted mt-5 fw-light mb-0">Events</h4>
                                <div className="events pt-4 px-3">
                                    <div className="wrapper d-flex mb-2">
                                        <i className="ti-control-record text-primary me-2"></i>
                                        <span>Feb 11 2018</span>
                                    </div>
                                    <p className="mb-0 font-weight-thin text-gray">Creating component page build a js</p>
                                    <p className="text-gray mb-0">The total number of sessions</p>
                                </div>
                                <div className="events pt-4 px-3">
                                    <div className="wrapper d-flex mb-2">
                                        <i className="ti-control-record text-primary me-2"></i>
                                        <span>Feb 7 2018</span>
                                    </div>
                                    <p className="mb-0 font-weight-thin text-gray">Meeting with Alisa</p>
                                    <p className="text-gray mb-0 ">Call Sarah Graves</p>
                                </div>
                                <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div></div><div className="ps__rail-y" style={{ top: "0px", height: "610px", right: "0px" }} ><div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "482px" }}></div></div></div>

                            <div className="tab-pane fade" id="chats-section" role="tabpanel" aria-labelledby="chats-section">
                                <div className="d-flex align-items-center justify-content-between border-bottom">
                                    <p className="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">Friends</p>
                                    <small className="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 fw-normal">See All</small>
                                </div>
                                <ul className="chat-list">
                                    <li className="list active">
                                        <div className="profile"><img src="images/faces/face1.jpg" alt="image" /><span className="online"></span></div>
                                        <div className="info">
                                            <p>Thomas Douglas</p>
                                            <p>Available</p>
                                        </div>
                                        <small className="text-muted my-auto">19 min</small>
                                    </li>
                                    <li className="list">
                                        <div className="profile"><img src="images/faces/face2.jpg" alt="image" /><span className="offline"></span></div>
                                        <div className="info">
                                            <div className="wrapper d-flex">
                                                <p>Catherine</p>
                                            </div>
                                            <p>Away</p>
                                        </div>
                                        <div className="badge badge-success badge-pill my-auto mx-2">4</div>
                                        <small className="text-muted my-auto">23 min</small>
                                    </li>
                                    <li className="list">
                                        <div className="profile"><img src="images/faces/face3.jpg" alt="image" /><span className="online"></span></div>
                                        <div className="info">
                                            <p>Daniel Russell</p>
                                            <p>Available</p>
                                        </div>
                                        <small className="text-muted my-auto">14 min</small>
                                    </li>
                                    <li className="list">
                                        <div className="profile"><img src="images/faces/face4.jpg" alt="image" /><span className="offline"></span></div>
                                        <div className="info">
                                            <p>James Richardson</p>
                                            <p>Away</p>
                                        </div>
                                        <small className="text-muted my-auto">2 min</small>
                                    </li>
                                    <li className="list">
                                        <div className="profile"><img src="images/faces/face5.jpg" alt="image" /><span className="online"></span></div>
                                        <div className="info">
                                            <p>Madeline Kennedy</p>
                                            <p>Available</p>
                                        </div>
                                        <small className="text-muted my-auto">5 min</small>
                                    </li>
                                    <li className="list">
                                        <div className="profile"><img src="images/faces/face6.jpg" alt="image" /><span className="online"></span></div>
                                        <div className="info">
                                            <p>Sarah Graves</p>
                                            <p>Available</p>
                                        </div>
                                        <small className="text-muted my-auto">47 min</small>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>


                    <nav className="sidebar sidebar-offcanvas shadow" id="sidebar">
                        <ul className="nav">
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/dashboard" >
                                    <i className="mdi mdi-home-outline mdi-18px" />
                                    <span className="menu-title ps-3">Dashboard</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/users" >
                                    <i className="mdi mdi-account-multiple-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Users</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/masterwallet" >
                                    <i className="mdi mdi-wallet-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Master Wallet</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/userwallet" >
                                    <i className="mdi mdi-wallet-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">User Wallet</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/samplerequests" >
                                    <i className="mdi mdi-briefcase-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Sample Requests</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/clients" >
                                    <i className="mdi mdi-account-multiple-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Clients</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#category" aria-expanded="false" aria-controls="desktopAudit">
                                    <i className="mdi mdi-format-list-bulleted mdi-18px"></i>
                                    <span className="menu-title  ps-3">Category</span>
                                </a>
                                <div className="collapse mx-4" id="category">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <NavLink className="nav-link pt-0" tag={Link} to="/admin/createcategory" >
                                                <NavLink className="text-dark" className="d-inline ps-3">Create Category</NavLink>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" tag={Link} to="/admin/assigncategoryprice" >
                                                <span className="d-inline  ps-3">Assign Category Price</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </NavItem>
                            <NavItem className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#withDrawal" aria-expanded="false" aria-controls="desktopAudit">
                                    <i className="mdi mdi-account-multiple-outline mdi-18px"></i>
                                    <span className="menu-title  ps-3">Seller</span>
                                </a>
                                <div className="collapse mx-4" id="withDrawal">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <NavLink className="nav-link pt-0" tag={Link} to="/admin/sellerwithdrawallist" >
                                                <NavLink className="text-dark" className="d-inline ps-3">Withdrawals List</NavLink>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" tag={Link} to="/admin/sellerwithdrawalreport" >
                                                <span className="d-inline  ps-3">Withdrawals Report</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </NavItem>
                            <NavItem className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#numbers" aria-expanded="false" aria-controls="desktopAudit">
                                    <i className="mdi mdi-format-list-bulleted mdi-18px"></i>
                                    <span className="menu-title  ps-3">Numbers</span>
                                </a>
                                <div className="collapse mx-4" id="numbers">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item pt-0">
                                            <NavLink className="nav-link pt-0" tag={Link} to="/admin/uploadnumbers" >
                                                <NavLink className="text-dark" className="d-inline ps-3">Upload Numbers</NavLink>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" tag={Link} to="/admin/specialnumbers" >
                                                <span className="d-inline  ps-3">Special Numbers</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/archivehistory" >
                                    <i className="mdi mdi-briefcase-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Archive History</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink className="nav-link" tag={Link} to="/admin/depositrequests" >
                                    <i className="mdi mdi-briefcase-outline mdi-18px"></i>
                                    <span className="menu-title ps-3">Deposits Request's</span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <div className="text-center" style={{ 'position': 'relative', 'bottom': '80px', 'left': '0px', 'top': '30px' }}>
                            <p className="text-muted">Complies with<br />
                                {/* <img src="/images/yucorp-logo.png" alt="yucorp.biz logo" width="90" />*/}
                                <img src="/images/gdpr-logo.png" alt="gdpr-logo" className="pt-2" width="120" />

                            </p>
                            <p className="text-muted mt-4">Partner with<br />
                                {/* <img src="/images/yucorp-logo.png" alt="yucorp.biz logo" width="90" />*/}
                                <img src="/images/survey-yu-logo.png" alt="survey-yu logo" className="pt-2" width="90" />
                            </p>
                        </div>
                    </nav>

                    <div className="app-content">
                        <div className="main">

                            {/*<CreateCategory />*/}
                            <Switch>
                                <Route
                                    exact path={`${match.path}`}
                                    render={(props) => <Dashboard {...props} />} />
                                <Route path={`${match.path}/dashboard`} component={Dashboard} />
                                <Route path={`${match.path}/users`} component={Users} />
                                <Route path={`${match.path}/masterwallet`} component={MasterWallet} />
                                <Route path={`${match.path}/userwallet`} component={UserWallet} />
                                <Route path={`${match.path}/samplerequests`} component={SampleRequests} />
                                <Route path={`${match.path}/clients`} component={Clients} />
                                <Route path={`${match.path}/createcategory`} component={CreateCategory} />
                                <Route path={`${match.path}/assigncategoryprice`} component={AssignCategoryPrice} />
                                <Route path={`${match.path}/sellerwithdrawallist`} component={SellerWithdrawalList} />
                                <Route path={`${match.path}/sellerwithdrawalreport`} component={SellerWithdrawalReport} />
                                <Route path={`${match.path}/uploadnumbers`} component={UploadNumbers} />
                                <Route path={`${match.path}/specialnumbers`} component={SpecialNumbers} />
                                <Route path={`${match.path}/archivehistory`} component={ArchiveHistory} />
                                <Route path={`${match.path}/depositrequests`} component={DepositRequests} />
                            </Switch>
                        </div>
                        <footer className="footer tabTheme  bottom-0">
                            <div className="col-xl-12">
                                <span className="d-block mt-1 mt-sm-0 text-center">Copyright <span className="pt-1"><i className="mdi mdi-copyright"></i> </span>2022 <a href="#" className="text-decoration-none">GoData Pedia</a>. All rights reserved</span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        )
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}


AdminLayout.propTypes = {
    match: PropTypes.any.isRequired
}

export default AdminLayout