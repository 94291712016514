import React, { Component, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import { ReactSession } from 'react-client-session';

//import DataTable from "react-data-table-component";
//import DataTableExtensions from "react-data-table-component-extensions";
//import "react-data-table-component-extensions/dist/index.css";


export class MasterWallet extends Component {

    static displayName = MasterWallet.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { MasterWallet: [], loading: true, Amount: "", Balance: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,

        });

    }
    componentDidMount() {
        this.populateMasterWallet();
    }

    renderMasterWalletTable(MasterWallet, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered data-table">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">S.No</th>
                        <th className="fontSmall fontBold">Date</th>
                        {/*  <th className="fontSmall fontBold">Email</th>*/}
                        <th className="fontSmall fontBold">Trans Type</th>
                        <th className="fontSmall fontBold">Credit(+)</th>
                        <th className="fontSmall fontBold">Debit(-)</th>
                        <th className="fontSmall fontBold">Credited To(-)</th>
                        <th className="fontSmall fontBold">Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {MasterWallet.map(w =>
                        <tr key={w.id}>
                            <td>
                                {w.id}
                            </td>
                            <td>
                                {w.created_datetime}
                            </td>
                            {/*<td>*/}

                            {/*</td>*/}
                            <td>
                                {w.trans_type == 1 ? 'Credit' : 'Debit'}
                            </td>
                            <td>
                                {/*{w.trans_type == 1 ? '' + { w.trans_amount } +'' : '0'}*/}
                                {w.trans_type == 1 ? w.trans_amount : "-"}
                            </td>
                            <td>
                                {w.trans_type == 1 ? "-" : w.trans_amount}


                            </td>
                            <td>
                                {w.creditedTo}

                            </td>
                            <td>
                                {w.balance}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateMasterWallet();
    };
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/masterwalletapi/post', {
                headers: {
                    'Authorization': 'Basic ' + token,
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Amount: this.state.Amount,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.refreshData();
                        this.setState({ Message: jsonData.message });
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderMasterWalletTable(this.state.MasterWallet, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*  <h4 className="my-3">MasterWallet</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Master Wallet</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Wallet</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Master Wallet</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '15px' }}>
                        <strong>
                            {this.state.Message}
                        </strong>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card custom-card">
                        <div className="row">
                            <div className="col-xl-12 col-sm-12 col-md-12">
                                <div className="bg-warning-transparent p-4 d-block">
                                    <div className="row">
                                        <div className="col-lg-1">  <img src="../images/wallet.png" alt="wallet icon" width="60" height="100%" className="pt-1" /></div>
                                        <div className="col-lg-7"> <h3 className="font-weight-bold h2 text-warning mb-1">Gp {this.state.Balance}</h3>
                                            <p>Current wallet balance</p></div>
                                        <div className="col-lg-4"> <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-xl-12 col-sm-12 col-md-12 d-flex">
                                                    <input className="form-control form-control-lg mr-3" name="Amount" id="Amount" onChange={this.handleInputChange} placeholder="Enter Topup Amount" type="text" />&nbsp;&nbsp;
                                                    <button type="submit" className="btn btn-rounded btn-primary mt-2 ml-4">Topup</button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 mb-5 mt-5">
                    <div className="card custom-card">
                        <div className="card-body">
                            <h5 className="pb-3">All Transaction Details</h5>
                            <div className="container-fluid px-0 tab-pane active">
                                {contents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateMasterWallet() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/masterwalletapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ MasterWallet: data.data, Balance: data.data.length == 0 ? 0 : data.data[0].balance, loading: false });
        }
    }
}

export default MasterWallet;

